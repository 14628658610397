<template>
  <div class="bgcard">
    <div class="headerMsg">
      <div class="addbtn">
        <button @click="adddevice">+ {{ $t("lang.Adddevice") }}</button>
      </div>
      <div class="searchlist">
        <div class="select_b" style="margin-right: 0.2rem">
          <span style="color: #bbbbbb">{{ $t("lang.Devicestatus") }}：</span>
          <el-select
            style="height: 0.4rem; width: 1.5rem"
            v-model="value1"
            :placeholder="$t('lang.Please')"
            popper-class="select_a"
            @change="stauts"
          >
            <el-option :label="$t('lang.whole')" value=""></el-option>
            <el-option :label="$t('lang.onLine')" :value="1"></el-option>
            <el-option :label="$t('lang.offline')" :value="0"></el-option>
          </el-select>
        </div>
        <!-- <div class="select_b" style="margin-right: 0.2rem">
          <span style="color: #bbbbbb">{{ $t("lang.Operationtype") }}：</span>
          <el-select
            style="height: 0.4rem; width: 1.5rem"
            v-model="value2"
            :placeholder="$t('lang.Please')"
            popper-class="select_a"
            @change="types"
          >
            <el-option :label="$t('lang.whole')" value=""></el-option>
            <el-option :label="$t('lang.lease')" :value="2"></el-option>
            <el-option :label="$t('lang.sale')" :value="3"></el-option>
          </el-select>
        </div> -->
        <div class="query">
          <div class="inputname">
            <input
              v-model="value3"
              :placeholder="$t('lang.PleaseenterthedevicenameorSn')"
            />
          </div>
          <div class="searchbtn">
            <button @click="search">
              <p>{{ $t("lang.search") }}</p>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="serverfroms">
      <el-table :data="tableData.list" style="width: 100%">
        <template slot="empty">
          <div class="noCart">{{ $t("lang.tableNull") }}</div>
        </template>
        <el-table-column
          prop="name"
          :label="$t('lang.equipmentName')"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="modelName"
          :label="$t('lang.model')"
          align="center"
        >
        </el-table-column>
        <el-table-column :label="$t('lang.User')" align="center">
          <template #default="scope">
            <span v-if="scope.row.state == 0">/</span>
            <span v-else>{{ scope.row.username }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.Devicestatus')" align="center">
          <template #default="scope">
            <span v-if="scope.row.status == 0">{{ $t("lang.offline") }}</span>
            <span v-else>{{ $t("lang.onLine") }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.workingMode')" align="center">
          <template #default="scope">
            <span v-if="scope.row.workMode == 0">{{
              $t("lang.livebroadcast")
            }}</span>
            <span v-if="scope.row.workMode == 1">{{
              $t("lang.Losslessrecordingandbroadcasting")
            }}</span>
            <span v-if="scope.row.workMode == 2">{{
              $t("lang.filetransfer")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="sr" :label="$t('lang.workingHours')" align="center">
        </el-table-column>
        <el-table-column prop="recvRate" :label="$t('lang.transmissionRate')" align="center">
        </el-table-column>
        <el-table-column prop="modelName" :label="$t('lang.electricitySurplus')" align="center">
          <template #default="scope">
            <span v-if="scope.row.deviceRealtimeInfoDto">{{ scope.row.deviceRealtimeInfoDto.batteryRemain }}%</span>
            <!-- <span v-else>{{ $t("lang.onLine") }}</span> -->
          </template>
        </el-table-column>
        <el-table-column prop="modelName" :label="$t('lang.inputSystem')" align="center">
          <template #default="scope">
            <span v-if="scope.row.deviceRealtimeInfoDto">{{ scope.row.deviceRealtimeInfoDto.inputFmtStr }}</span>
            <!-- <span v-else>{{ $t("lang.onLine") }}</span> -->
          </template>
        </el-table-column>
        <el-table-column prop="cache" :label="$t('lang.delaySets')" align="center">
          <template #default="scope">
            <span v-if="scope.row.configDto">{{ scope.row.configDto.cache }}</span>
            <!-- <span v-else>{{ $t("lang.onLine") }}</span> -->
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('lang.operation')"
          width="280"
          align="center"
        >
          <template #default="scope">
            <div class="bottom_tabes">
              <el-tooltip
                effect="dark"
                :content="$t('lang.edit')"
                placement="left"
              >
                <div
                  class="bottom_imgs bottom_compile"
                  @mouseenter="enterOne(3, scope.row.id, scope.row.flag3)"
                  @mouseleave="leaveOne(3, scope.row.id, scope.row.flag3)"
                  @click="editmsg(scope.row, scope.row.status == 1 ? 2 : 1)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag3 === 0"
                    src="@/assets/imgs/bottom/bianji.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                    v-if="scope.row.flag3 === 1"
                    src="@/assets/imgs/bottom/bianji2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
              <div class="btn_dise">|</div>
              <el-tooltip
                effect="dark"
                :content="$t('lang.details')"
                placement="left"
              >
                <div
                  class="bottom_imgs bottom_detail"
                  @mouseenter="enterOne(4, scope.row.id, scope.row.flag4)"
                  @mouseleave="leaveOne(4, scope.row.id, scope.row.flag4)"
                  @click="detailsmsg(scope.row)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag4 === 0"
                    src="@/assets/imgs/bottom/xiangqing.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                    v-if="scope.row.flag4 === 1"
                    src="@/assets/imgs/bottom/xiangqing2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
              <div class="btn_dise">|</div>
              <el-tooltip
                effect="dark"
                :content="$t('lang.deletes')"
                placement="left"
              >
                <div
                  class="bottom_imgs bottom_delete"
                  @mouseenter="enterOne(5, scope.row.id, scope.row.flag5)"
                  @mouseleave="leaveOne(5, scope.row.id, scope.row.flag5)"
                  @click="deletemsg(scope.row, 3)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag5 === 0"
                    src="@/assets/imgs/bottom/shanchu.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                    v-if="scope.row.flag5 === 1"
                    src="@/assets/imgs/bottom/shanchu2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; align-items: center" v-if="tableData.list">
        <div class="allNum">{{ $t("lang.total") }}: {{ tableData.total }}</div>
        <el-pagination
          popper-class="paging"
          background
          layout="prev, pager, next"
          :total="tableData.total"
          :current-page="Number(parsm.page)"
          @current-change="handlePageCurrentChange"
          :prev-text="$t('lang.previouspage')"
          :next-text="$t('lang.nextpage')"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 添加、编辑模态框 -->
    <el-dialog
      :title="title"
      :visible.sync="showSee"
      :show-close="false"
      width="30%"
      top="2.5rem"
      custom-class="seteqcs_a"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="setmsgs"
        v-if="sing == 1"
      >
        <el-form-item :label="$t('lang.equipmentName') + '：'" prop="name">
          <el-input v-model="ruleForm.name" maxlength="32"></el-input>
        </el-form-item>
        <el-form-item :label="$t('lang.serialnumber') + '：'" prop="mid">
          <el-input v-model="ruleForm.mid" maxlength="32"></el-input>
        </el-form-item>
        <el-form-item :label="$t('lang.Operationtype') + '：'" prop="type">
          <el-select
            v-model="ruleForm.type"
            :disabled="rowObg.username ? true : false"
            :placeholder="$t('lang.Please')"
          >
            <el-option :label="$t('lang.sale')" :value="3"></el-option>
            <el-option :label="$t('lang.lease')" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('lang.Equipmentmodel') + '：'" prop="modelID">
          <el-select
            v-model="ruleForm.modelID"
            :placeholder="$t('lang.Please')"
          >
            <el-option
              v-for="(item, index) in models"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form
        :model="ruleForm_a"
        :rules="rules_a"
        ref="ruleForm_a"
        class="setmsgs"
        v-else
      >
        <el-form-item :label="$t('lang.equipmentName') + '：'" prop="name">
          <el-input v-model="ruleForm_a.name" maxlength="32"></el-input>
        </el-form-item>
        <el-form-item :label="$t('lang.Operationtype') + '：'" prop="type">
          <el-select
            v-model="ruleForm_a.type"
            :disabled="rowObg.username ? true : false"
            :placeholder="$t('lang.Please')"
          >
            <el-option :label="$t('lang.sale')" :value="3"></el-option>
            <el-option :label="$t('lang.lease')" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('lang.workingMode') + '：'" prop="workMode">
          <el-select
            v-model="ruleForm_a.workMode"
            :placeholder="$t('lang.Please')"
          >
            <el-option :label="$t('lang.livebroadcast')" :value="0"></el-option>
            <el-option
              :label="$t('lang.Losslessrecordingandbroadcasting')"
              :value="1"
            ></el-option>
            <el-option :label="$t('lang.filetransfer')" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('lang.codingAlgorithm') + '：'"
          prop="encodeType"
        >
          <el-select
            v-model="ruleForm_a.encodeType"
            :placeholder="$t('lang.Please')"
          >
            <el-option label="H264" :value="0"></el-option>
            <el-option label="h265" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('lang.transmissionRate') + '：'"
          prop="videoBitrate"
          class="control"
        >
          <el-input v-model="ruleForm_a.videoBitrate"></el-input>
          <span>KB</span>
        </el-form-item>
        <el-form-item
          :label="$t('lang.transportProtocol') + '：'"
          prop="protocol"
        >
          <el-select
            v-model="ruleForm_a.protocol"
            :placeholder="$t('lang.Please')"
          >
            <el-option label="UDP" :value="1"></el-option>
            <el-option label="TCP" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('lang.buffer') + '：'"
          prop="cache"
          class="control"
        >
          <el-input v-model="ruleForm_a.cache"></el-input>
          <span>ms</span>
        </el-form-item>
        <el-form-item :label="$t('lang.Bitratemode') + '：'" prop="videoCbr">
          <el-select
            v-model="ruleForm_a.videoCbr"
            :placeholder="$t('lang.Please')"
          >
            <el-option label="VBR" :value="0"></el-option>
            <el-option label="cbr" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item :label='$t("lang.Codingsystem")+"："' prop="encodeFmt">
          <el-select v-model="ruleForm_a.encodeFmt" :placeholder="$t('lang.Please')">
			<el-option
			  v-for="item in encodeFmts"
			  :key="'ide_'+item.value"
			  :label="item.label"
			  :value="item.value"
			>
			</el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item
          :label="$t('lang.Encodingframerate') + '：'"
          prop="encodeFps"
        >
          <el-select
            v-model="ruleForm_a.encodeFps"
            :placeholder="$t('lang.Please')"
          >
            <el-option :label="$t('lang.Fullframerate')" :value="0"></el-option>
            <el-option :label="$t('lang.Halfframerate')" :value="1"></el-option>
          </el-select>
        </el-form-item>

        <!-- <div class="upgrade">
          <label>{{$t('lang.Equipmentupgrade')}}：</label>
          <div>
            <el-upload
              class="upload-demo"
              :action="url"
              :before-remove="beforeRemove"
              :limit="1"
              :on-exceed="
                () => {
                  $message.error($t('lang.Themaximumnumber'));
                }
              "
              :file-list="fileList"
              :on-success="success"
              :on-remove="remove"
              :before-upload="beforeUpload"
            >
              <el-button size="small" type="primary">+{{$t('lang.Selectfile')}}</el-button>
            </el-upload>
            <el-checkbox v-model="checked">{{$t('lang.Forcedupgradeafterupload')}}</el-checkbox>
          </div>
        </div>
        <el-form-item :label='$t("lang.Latestversionnumber")+"："' v-if="version" prop="softVer">
          <el-input v-model="ruleForm_a.softVer"></el-input>
        </el-form-item> -->
      </el-form>
      <div>
        <span class="dialog-footer">
          <button class="button_a" @click="showSee = false">
            <p>{{ $t("lang.cancel") }}</p>
          </button>
          <button
            class="button_a"
            @click="submitForm('ruleForm')"
            v-if="sing == 1"
          >
            <p>{{ $t("lang.submit") }}</p>
          </button>
          <button class="button_a" @click="submitForm('ruleForm_a')" v-else>
            <p>{{ $t("lang.preservation") }}</p>
          </button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      :title="titles"
      :visible.sync="showSees"
      :show-close="false"
      width="25%"
      top="2.5rem"
      custom-class="seteqcs_a"
    >
      <div v-if="sing_a == 1">
        <div class="binding">
          <label>{{ $t("lang.mailboxss") }}：</label>
          <el-input
            v-model="bindings.email"
            :placeholder="$t('lang.Pleaseinputemailss')"
          ></el-input>
        </div>
      </div>
      <div class="delete" v-if="sing_a == 2">
        <span>{{ $t("lang.Areyousureyouwanttounbind") }}？</span>
      </div>
      <div class="delete" v-if="sing_a == 3">
        <span>{{ $t("lang.Areyousureyouwanttodeletes") }}？</span>
      </div>
      <div>
        <span class="dialog-footer">
          <button class="button_a" @click="showSees = false">
            <p>{{ $t("lang.cancel") }}</p>
          </button>
          <button class="button_a" @click="determine">
            <p>{{ $t("lang.determine") }}</p>
          </button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as pment from "@/api/equipment.js";
import * as RegExpx from "@/ui-utils/RegExp.js";
import * as baseURL from "@/utils/request.js";
export default {
  data() {
    return {
      value1: 1,
      value2: "",
      value3: "",
      tableData: [],
      parsm: {
        page: 1,
        size: 10,
        status: 1,
        type: "",
        snOrName: "",
      },
      showSee: false,
      title: "",
      sing: "",
      ruleForm: {
        //添加设备参数
        name: "",
        mid: "",
        type: "",
        modelID: "",
        adminEmail: "",
        id: "",
      },
      ruleForm_a: {
        name: "",
        workMode: "",
        encodeType: "",
        videoBitrate: "",
        protocol: "",
        outputPolicy: "",
        cache: "",
        videoCbr: "",
        encodeFps: "",
        softVer: "",
      },
      deviceid: "",

      fileList: [],
      checked: false,
      titles: "",
      showSees: false,
      sing_a: "",
      models: "", //设备型号
      bindings: {
        //捆绑用户参数
        email: "",
        mid: "",
      },
      rowObg: {},
      version: false,
      file: {}, //上传文件信息
      url: "", //文件上传路径
      encodeFmts: [
        //编码制式
        { value: 0, label: "NONE" },
        { value: 1, label: "NTSC" },
        { value: 2, label: "NTSC23.98" },
        { value: 3, label: "PAL" },
        { value: 4, label: "NTSCP" },
        { value: 5, label: "PALP" },
        { value: 6, label: "1080P23.98" },
        { value: 7, label: "1080P24" },
        { value: 8, label: "1080P25" },
        { value: 9, label: "1080P2997" },
        { value: 10, label: "1080P30" },
        { value: 11, label: "1080I50" },
        { value: 12, label: "1080I59.94" },
        { value: 13, label: "1080I60" },
        { value: 14, label: "1080P50" },
        { value: 15, label: "1080P59.94" },
        { value: 16, label: "1080P60" },
        { value: 17, label: "720P50" },
        { value: 18, label: "720P59.94" },
        { value: 19, label: "720P60" },
        { value: 20, label: "2160P25" },
        { value: 21, label: "2160P30" },
        { value: 22, label: "2160P50" },
        { value: 23, label: "2160P60" },
        { value: 24, label: "STREAM" },
      ],
    };
  },
  computed: {
    rules_a() {
      var bitrate = (rule, value, callback) => {
        if (value == "") {
          callback(this.$t("lang.Pleaseinputcoderate"));
        } else if (
          value < 100 ||
          value > 10240 ||
          !RegExpx.integer.test(value)
        ) {
          callback(
            new Error(this.$t("lang.Pleaseenterapositiveintegerfrom100to10240"))
          );
        } else {
          callback();
        }
      };
      var cache = (rule, value, callback) => {
        if (value == "") {
          callback(this.$t("lang.Pleaseinputbuffertime"));
        } else if (value > 30000 || !RegExpx.integer.test(value)) {
          callback(
            new Error(this.$t("lang.Pleaseenterapositiveintegerfrom0to30000"))
          );
        } else {
          callback();
        }
      };
      return {
        //编辑设备正则验证
        name: [
          {
            required: true,
            message: this.$t("lang.Pleaseenterthedevicename"),
            trigger: "blur",
          },
        ],
        workMode: [
          {
            required: true,
            message: this.$t("lang.Pleaseselectworkingmode"),
            trigger: "change",
          },
        ],
        encodeType: [
          {
            required: true,
            message: this.$t("lang.Pleaseselecttheencodingalgorithm"),
            trigger: "change",
          },
        ],
        videoBitrate: [
          {
            required: true,
            validator: bitrate,
            trigger: "blur",
          },
        ],
        protocol: [
          {
            required: true,
            message: this.$t("lang.Pleaseselecttransportprotocol"),
            trigger: "change",
          },
        ],
        outputPolicy: [
          {
            required: true,
            message: this.$t("lang.Pleaseselectoutputpolicy"),
            trigger: "change",
          },
        ],
        cache: [
          {
            required: true,
            validator: cache,
            trigger: "blur",
          },
        ],
        videoCbr: [
          {
            required: true,
            message: this.$t("lang.Pleaseselectthecoderatemode"),
            trigger: "change",
          },
        ],
        encodeFps: [
          {
            required: true,
            message: this.$t("lang.Pleaseselectencodingframerate"),
            trigger: "change",
          },
        ],
        softVer: [
          {
            required: true,
            message: this.$t("lang.Pleaseenterthelatestversionnumber"),
            trigger: "blur",
          },
        ],
      };
    },
    rules() {
      var serial = (rule, value, callback) => {
        if (value === "") {
          callback(this.$t("lang.Pleaseentertheserialnumber"));
        } else if (
          value.length < 16 ||
          (value.length > 16 && value.length < 32)
        ) {
          callback(this.$t("lang.Theserial"));
        } else if (RegExpx.words.test(value) || RegExpx.special.test(value)) {
          callback(new Error(this.$t("lang.Theserialnumbercannot")));
        } else {
          callback();
        }
      };
      return {
        //添加设备正则验证
        name: [
          {
            required: true,
            message: this.$t("lang.Pleaseenterthedevicename"),
            trigger: "blur",
          },
        ],
        mid: [
          {
            required: true,
            validator: serial,
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: this.$t("lang.Pleaseselectoperationtype"),
            trigger: "change",
          },
        ],
        modelID: [
          {
            required: true,
            message: this.$t("lang.Pleaseselectthedevicemodel"),
            trigger: "change",
          },
        ],
      };
    },
  },
  mounted() {
    this.url = baseURL.upload;
    if (this.$route.query.page) {
      this.parsm.page = Number(this.$route.query.page);
    }
    this.getList();
  },
  methods: {
    enterOne(index, ids, flag) {
      for (let i in this.tableData.list) {
        if (index === 1) {
          if (ids == this.tableData.list[i].id && flag === 0) {
            this.tableData.list[i].flag1 = 1;
          }
        }
        if (index === 2) {
          if (ids == this.tableData.list[i].id && flag === 0) {
            this.tableData.list[i].flag2 = 1;
          }
        }
        if (index === 3) {
          if (ids == this.tableData.list[i].id && flag === 0) {
            this.tableData.list[i].flag3 = 1;
          }
        }
        if (index === 4) {
          if (ids == this.tableData.list[i].id && flag === 0) {
            this.tableData.list[i].flag4 = 1;
          }
        }
        if (index === 5) {
          if (ids == this.tableData.list[i].id && flag === 0) {
            this.tableData.list[i].flag5 = 1;
          }
        }
      }
    },
    leaveOne(index, ids, flag) {
      for (let i in this.tableData.list) {
        if (index === 1) {
          if (ids == this.tableData.list[i].id && flag === 1) {
            this.tableData.list[i].flag1 = 0;
          }
        }
        if (index === 2) {
          if (ids == this.tableData.list[i].id && flag === 1) {
            this.tableData.list[i].flag2 = 0;
          }
        }
        if (index === 3) {
          if (ids == this.tableData.list[i].id && flag === 1) {
            this.tableData.list[i].flag3 = 0;
          }
        }
        if (index === 4) {
          if (ids == this.tableData.list[i].id && flag === 1) {
            this.tableData.list[i].flag4 = 0;
          }
        }
        if (index === 5) {
          if (ids == this.tableData.list[i].id && flag === 1) {
            this.tableData.list[i].flag5 = 0;
          }
        }
      }
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.parsm.page = page;
      this.getList();
    },
    //状态筛选
    stauts(e) {
      this.parsm.status = e;
      this.getList();
    },
    //类型筛选
    types(e) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.type = e;
      this.getList();
    },
    //设备名称和sn筛选
    search() {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.snOrName = this.value3;
      this.getList();
    },
    //获取设备列表
    async getList() {
      let res = await pment.list(this.parsm);

      if (res.data) {
        res.data.list.forEach((item) => {
          item.flag1 = 0;
          item.flag2 = 0;
          item.flag3 = 0;
          item.flag4 = 0;
          item.flag5 = 0;
          if (item.deviceRealtimeInfoDto && item.deviceRealtimeInfoDto.workModeStatus) {
            let sr = item.deviceRealtimeInfoDto.workModeStatus.startTime;
            let hr = parseInt((sr / 60 / 60) % 24);
            let min = parseInt((sr / 60) % 60);
            let sec = parseInt(sr % 60);
            hr = hr > 9 ? hr : "0" + hr;
            min = min > 9 ? min : "0" + min;
            sec = sec > 9 ? sec : "0" + sec;
            item.sr = `${hr}:${min}:${sec}`;
          }else{
            item.sr = ''
          }
          if(item.deviceRealtimeInfoDto){
            item.deviceRealtimeInfoDto.recvRate = item.deviceRealtimeInfoDto.recvRate.split(',');
          item.recvRate = item.deviceRealtimeInfoDto.recvRate[item.deviceRealtimeInfoDto.recvRate.length-1];
          }
          
          
        });
        this.tableData = res.data;
      } else {
        this.tableData = [];
      }
      //console.log(res);
    },
    //添加设备
    adddevice() {
      this.GetModel(1);
      this.showSee = true;
      this.sing = 1;
      this.title = this.$t("lang.Adddevice");
      this.rowObg = "";
      this.$nextTick(() => {
        this.$refs["ruleForm"].clearValidate();
        for (let key in this.ruleForm) {
          this.ruleForm[key] = "";
        }
        this.ruleForm.type = 2;
      });
    },
    //获取设备型号
    async GetModel(ind) {
      let res = await pment.model();
      if (res.data) {
        this.models = res.data;
        if (ind === 1) {
          this.ruleForm.modelID = this.models[0].id;
        }
      }
    },
    //捆绑用户
    bindingmsg(row, ind) {
      this.$forceUpdate();
      this.bindings.email = "";
      this.bindings.mid = row.mid;
      this.showSees = true;
      this.sing_a = ind;
      if (ind == 1) {
        this.titles = this.$t("lang.Bundledusers");
      } else {
        this.titles = this.$t("lang.Unbindingusers");
      }
    },
    //编辑设备
    editmsg(row, ind) {
      this.$router.push({
        path: "/nav/manage/components",
        query: { mid: row.mid },
      });
      // this.sing = ind;
      // this.rowObg = row;
      // this.showSee = true;
      // if (ind == 2) {
      //   this.fileList = [];
      //   this.file = {};
      //   //this.file.targetModel = row.modelName;
      //   //this.ruleForm_a.softVer = "";
      //   this.version = false;
      //   this.checked = false;

      //   this.title = this.$t("lang.Editingdevice");

      //   this.$nextTick(() => {
      //     this.$refs["ruleForm_a"].clearValidate();
      //   });
      //   pment.parameter({ mid: row.mid }).then((res) => {
      //     // console.log(res);
      //     this.ruleForm_a = res.data;
      //     this.ruleForm_a.type = row.type;
      //   });
      //   this.deviceid = row.id;
      // }
      // if (ind == 1) {
      //   this.GetModel();
      //   console.log(row);
      // 	this.$nextTick(() => {
      // 	  this.$refs["ruleForm"].clearValidate();
      // 	});
      //   this.title = this.$t("lang.Editingdevice");
      //   this.ruleForm.name = row.name;
      //   this.ruleForm.mid = row.mid.slice(16);
      //   this.ruleForm.type = row.type;
      //   this.ruleForm.modelID = row.modelID;
      //   this.ruleForm.id = row.id;
      // }
    },
    //设备详情
    detailsmsg(row) {
      this.$router.push({
        path: "equipmentmssg",
        query: { mid: row.mid, page: this.parsm.page },
      });
    },
    //删除设备
    deletemsg(row, ind) {
      this.bindings.mid = row.mid;
      this.bindings.name = row.name;
      this.showSees = true;
      this.sing_a = ind;
      this.titles = this.$t("lang.Removedevice");
    },
    //刷新输入框内容
    cheng() {
      this.$forceUpdate();
    },
    edit() {
      console.log(this.ruleForm_a);
      pment.edit(this.ruleForm_a).then((res) => {
        console.log(res);
        this.$message.success(this.$t("lang.Submitted"));
        this.showSee = false;
        this.getList();
      });
    },
    //提交内容
    submitForm(formName) {
      let adminemail = sessionStorage.getItem("user");
      if (adminemail) {
        this.ruleForm.adminEmail = JSON.parse(adminemail).email;
        this.ruleForm_a.adminEmail = JSON.parse(adminemail).email;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.sing == 1) {
            if (this.ruleForm.mid.length == 16) {
              this.ruleForm.mid = "0000000000000000" + this.ruleForm.mid;
            }
            pment.add(this.ruleForm).then((res) => {
              this.$message.success(this.$t("lang.Submitted"));
              this.showSee = false;
              this.getList();
            });
          } else {
            this.ruleForm_a.deviceId = this.deviceid;
            //console.log(this.ruleForm_a);
            this.edit();
          }
        } else {
          return false;
        }
      });
    },
    //上传成功之后
    success(res) {
      if (res) {
        this.$message.success(this.$t("lang.Uploadsucceeded"));
        this.file.url = res.data.path;
        this.file.name = res.data.name;
        this.file.size = res.data.size;
        this.version = true;
      }
    },
    //删除文件之前
    beforeRemove(file) {
      return this.$confirm(
        this.$t("lang.Areyousureyouwanttoremove") + `${file.name}？`
      );
    },
    //上传文件之前
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M)
        this.$message.error(this.$t("lang.Thesizeoftheuploadedfile") + "10MB!");
    },
    //删除文件之后
    remove() {
      this.version = false;
    },
    //捆绑、解绑、删除
    determine() {
      let adminemail = sessionStorage.getItem("user");
      let adminEmails = "";
      if (adminemail) {
        adminEmails = JSON.parse(adminemail).email;
      }
      //捆绑
      if (this.sing_a == 1) {
        if (this.bindings.email == "") {
          this.$message.error(this.$t("lang.Usermailboxcannotbeempty"));
          return false;
        }
        const reg = new RegExp(
          "(^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)*.[a-zA-Z0-9]{2,6}$)|(^0?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$)"
        );
        if (!reg.test(this.bindings.email)) {
          this.$message.error(this.$t("lang.Pleaseenterthecorrectemail"));
          return false;
        }
        let data = {
          email: this.bindings.email,
          mid: this.bindings.mid,
          adminEmail: adminEmails,
        };
        pment.binding(data).then((res) => {
          this.$message.success(this.$t("lang.bindingsa"));
          this.getList();
        });
      }
      //解绑
      if (this.sing_a == 2) {
        pment
          .unbinding({ mid: this.bindings.mid, adminEmail: adminEmails })
          .then((res) => {
            this.$message.success(this.$t("lang.Unbundlings"));
            this.getList();
          });
      }
      //删除
      if (this.sing_a == 3) {
        pment
          .deletes({
            mid: this.bindings.mid,
            adminEmail: adminEmails,
            name: this.bindings.name,
          })
          .then((res) => {
            this.$message.success(this.$t("lang.Operations"));
            this.isLastPage();
            this.getList();
          });
      }
      this.showSees = false;
    },
    //判断当前页是否是最后一页
    isLastPage() {
      let lastPage = Math.ceil((this.tableData.total - 1) / this.parsm.size);
      //console.log(lastPage)
      if (this.parsm.page == lastPage || this.parsm.page > lastPage) {
        this.parsm.page = lastPage;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;
}

.headerMsg {
  display: flex;
  justify-content: space-between;

  .addbtn {
    button {
      height: 0.4rem;
      border: none;
      outline-style: none;
      padding: 0px 0.12rem;
      background: #307b37;
      border-radius: 0.1rem;
      font-size: 0.16rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
}

.buttons {
  // padding: 0px;
  // width: 82px;
  // height: 42px;
  outline-style: none;
  border: none;
  border-radius: 0.04rem;
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}

/* 模态框 */
/deep/.setmsgs {
  .el-input,
  .el-select,
  .SelectTime {
    width: 2.5rem;
  }
  .el-form-item__label {
    display: inline-block;
    width: 1.4rem;
  }
  .el-form-item__content {
    margin-left: 0 !important;
  }
  .el-form-item {
    display: flex;
    align-items: center;
  }
  .control {
    .el-input {
      width: 2.2rem;
      margin-right: 0.1rem;
    }
  }
}
.delete {
  height: 0.4rem;
  text-align: center;
  font-size: 0.16rem;
  color: #333333;
}

.binding {
  margin-bottom: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  label {
    //width: 1rem;
  }
  .el-input,
  .el-select {
    width: 2.5rem;
  }
}

.button_a {
  border: none;
  outline-style: none;
  width: 0.92rem;
  height: 0.46rem;
  background: #ededed;
  border: 1px solid #438749;
  border-radius: 0.1rem;

  p {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #438749;
    line-height: 0.35rem;
    text-align: center;
  }
}

.button_a:nth-child(2) {
  width: 0.92rem;
  height: 0.46rem;
  background: #438749;
  border-radius: 0.1rem;
  margin-left: 0.2rem;

  p {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.35rem;
  }
}
</style>
